/* Marquee.css */
.animate-marquee {
    display: inline-flex;
    white-space: nowrap;
    animation: marquee 20s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
