@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Petit Cochon';
    src: url('./fonts/PetitCochon.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Super Shiny';
    src: url('./fonts/SuperShiny.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Halo Dek';
    src: url('./fonts/HaloDek.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.text-stroke-3 {
    text-shadow: -2px -2px 0 #f6924e, 2px -2px 0 #f6924e, -2px 2px 0 #f6924e, 2px 2px 0 #f6924e;
}